import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Document, PageTitle } from "@components"

const Terms = () => (
  <Layout>
    <Seo title="Terms & Conditions" />
    <PageTitle title="Terms & Conditions" />
    <Document>
      <p>In using this website you are deemed to have read and agreed to the following terms and conditions.</p>
      <p>The following terminology applies to these Terms and Conditions, Privacy Statement and any or all Agreements: “User“, “You“ and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “Kobo Capital“, “The Company“, “Ourselves”, “We” and “Us“, refers to our Company. “Party” or “Parties”, refers to both the You and Ourselves, or either the You or Ourselves. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
      <p>All contents in this website including information, text, software, photographs, videos, graphics, music, logos, button icons, images and audio clips and any other materials and services present on the Website (hereinafter, the "Content"), is the exclusive property of Kobo Capital Ltd and/or relevant third party licensors and it is protected by the relevant copyright and the other applicable laws on intellectual property.</p>
      <p>You are permitted to use the Content for your personal use only and for non-commercial purposes. The Content or any part of it can only be reproduced with the prior written consent of Kobo Capital, which may be requested by sending an email to info@kobofunds.com and, where applicable, with the prior written consent of the relevant third party licensor. Except where you have been given express permission to do so, the Content cannot be reproduced, amended, distributed, transmitted and/or broadcasted in any form whatsoever.</p>
      <p>The trademarks and logos on the Website (hereinafter, the "Trademarks") are trademarks (whether registered or not) owned by Kobo Capital or its Group undertakings or third party licensors. Nothing contained on the Website should be construed as granting any licence or right to use any of the Trademarks for any purpose whatsoever without the written permission, and the application of the relevant licence terms and conditions, where applicable, from the lawful owner. Unauthorised use of Trademarks or the Content is strictly prohibited and may amount to an infringement of the relevant copyright and the applicable laws on intellectual property.</p>
      <h4>Disclaimer</h4>
      <p>The information on this website is provided on an “as is” basis. To the fullest extent permitted by law, this Company excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.</p>
      <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. The contents of this website or any third-party website is subject to change without notice.</p>
      <p>We use IP addresses to analyse trends, administer the site, track user’s movement, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information. Additionally, for systems administration, detecting usage patterns and troubleshooting purposes, our web servers automatically log standard access information including browser type, access times/open mail, URL requested, and referral URL. This information is not shared with third parties and is used only within this Company on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above without your explicit permission. Please also familiarise yourself with our Privacy Policy.</p>
      <p>You may not create a link to any page of this website without our prior written consent. If you do create a link to a page of this website you do so at your own risk and the exclusions and limitations set out above will apply to your use of this website by linking to it.</p>
      <p>We do not monitor or review the content of other party’s websites which are linked to from this website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and we should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our site & to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>
      <p>The Content of the Website does not constitute or form part of any offer for the sale or subscription of any securities or other financial instruments by Kobo Capital, its Group undertakings or third party licensors.</p>
    </Document>
  </Layout>
)

export default Terms
